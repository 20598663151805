.App {
  text-align: center;
}


.title {
  font-size: 6vw;
  color: rgb(255, 255 ,255);
  font-family: Roboto, sans-serif;
  padding: 3%;
  background-color: rgba(110, 2, 250, 0.7);
  border-radius: 2%;
  margin-left: 10vw;
  margin-right: 10vw;
}

.adminPg {
  background-color: rgba(255,255,255, 0.7);
  padding: 3%;
  border-radius: 2%;
}

#root {
  background-attachment: fixed;
  background-image: url("./nutc1_cropped.jpg");
  background-size: cover;
  height: 100vh;
  background-position: center;
  width: 100vw;
}
.MuiTextField-root { 
  background-color: rgba(255,255,255, 0.7) !important;
 }

 .MuiStepper-root {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: rgba(0,0,0,0) !important;
   color: white;
 }

 .MuiDialog-root {
   padding: 2px;
 }

.description {
  font-size: 2.5vh;
  padding-top: 1vh;
  padding-right: 2px;
  padding-left: 2px;
  padding-bottom: 1vh;
  font-weight: 400;
  background-color: rgba(200, 200, 200, 0.9);
  color: rgb(74, 0, 172);
  border-radius: 5px;
  margin-bottom: 8vh;
}

.policy {
  font-size: 2vh;
  padding-top: 1vh;
  padding-right: 2px;
  padding-left: 2px;
  padding-bottom: 1vh;
  background-color: rgba(200, 200, 200, 0.9);
  color: rgb(74, 0, 172);
  border-radius: 5px;
}

@media screen and (min-width: 1000px){
  .title {
    font-size: 48px;
  }

  .description {
    font-size: 30px;
    font-weight: 400;
  }
}




.main {
  height: "100vh";
}

.muiForm {
  margin-bottom: 3vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
